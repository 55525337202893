<template>
  <v-card :height="(widget.id === 5 && widget.dataformat === 2) ? '370' : widget.id === 1 ? '460' : '420'" style="box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px 0px, rgba(27, 31, 35, 0.3) 0px 0px 0px 1px;" class="rounded-xl">
    <v-card-title :class="createdBy === 'me' ? 'py-0 pl-1' : 'py-2 pl-1'" class="pl-5">
      <span v-if="widget.id === 1" class="py-0 text-subtitle-2"> {{ $t('tickets') }} </span>
      <span v-else-if="widget.id === 2" class="py-0 text-subtitle-2"> {{ $t('recentDocuments') }} </span>
      <span v-else-if="widget.id === 3" class="py-0 text-subtitle-2"> {{ $t('recentActivities') }} </span>
      <span v-else-if="widget.id === 4" class="py-0 text-subtitle-2"> {{ $t('moduleInfo') }} </span>
      <span v-else-if="widget.id === 5" class="py-0 text-subtitle-2">{{widget.name }} </span>
      <span v-else-if="widget.id === 6" class="py-0 text-subtitle-2"> {{ $t('quickLinks') }} </span>
      <span v-else-if="widget.id === 7" class="py-0 text-subtitle-2"> {{ $t('hours') }} </span>
      <span v-else-if="widget.id === 8" class="py-0 text-subtitle-2"> {{ $t('ticketsOpenOrClose') }} </span>
      <span v-else class="py-0 text-subtitle-2"> {{ $t('myPersonalDocumentRouting') }} </span>
      <v-spacer></v-spacer>
      <v-btn @click="$emit('edit')" icon color="primary" fab small dark v-if="(createdBy === 'me')">
        <v-icon size="20">mdi-pencil-outline</v-icon>
      </v-btn>
      <v-btn v-if="createdBy === 'me'" class="ml-2" icon @click="$emit('remove')" color="primary" fab small dark>
        <v-icon size="20">mdi-window-close</v-icon>
      </v-btn>
      <!-- <v-icon v-else color="primary" size="18" :title="widget.createdBy">mdi-information</v-icon> -->
      <v-tooltip bottom v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-on="on" v-bind="attrs" color="primary" size="18">mdi-information</v-icon>
        </template>
        <span>{{ widget.createdBy }}</span>
      </v-tooltip>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pa-1 pb-0">
      <tickets v-if="widget.id === 1" :listOfWidgets="listOfWidgets" :ticketsLoading="widgetDetails.ticketsLoader" :chartLoading="widgetDetails.ticketsChartLoader" :widgetCount="widgetCount" :chartOptionsTickets="chartOptionsTickets" :index="index"></tickets>
      <recent-documents v-else-if="widget.id === 2" :listOfRecentDocuments="listOfRecentDocuments" :documentLoading="widgetDetails.loader"></recent-documents>
      <recent-activities v-else-if="widget.id === 3" :listOfRecentActivities="listOfRecentActivities" :activitesLoading="widgetDetails.loader"></recent-activities>
      <statistics v-else-if="widget.id === 4" :chartOptions="chartOptions" :moduleInfoLoading="widgetDetails.loader"></statistics>
      <user-defined v-else-if="widget.id === 5" :userDefinedLoading="widgetDetails.loader" :widget="widgetDetails" :pagination="pagination"></user-defined>
      <quick-links v-else-if="widget.id === 6" :listOfQuickLinks="listOfQuickLinks" :activitesLoading="widgetDetails.loader"></quick-links>
      <normalHours v-else-if="widget.id === 7" :hasTimerPermission="hasTimerPermission" :hoursChartOptions="hoursChartOptions" :hoursInfoLoading="widgetDetails.loader"></normalHours>
      <ticketsInfo v-else-if="widget.id === 8" :hasTicketPermission="hasTicketPermission" :ticketChartOptions="ticketChartOptions" :openCloseTicketsLoading="widgetDetails.loader"></ticketsInfo>
      <personal-workflow v-else-if="widget.id === 9" :listOfWorkflows="listOfWorkflows" :workflowLoading="widgetDetails.loader"></personal-workflow>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ['widgetDetails', 'pagination', 'listOfWidgets', 'widgetCount', 'chartOptionsTickets', 'index', 'createdBy', 'listOfRecentDocuments', 'listOfRecentActivities', 'chartOptions', 'widget', 'listOfQuickLinks', 'hasTimerPermission', 'hoursChartOptions', 'hasTicketPermission', 'ticketChartOptions', 'listOfWorkflows'],
  components: {
    tickets: () => import('@/components/dashboard/Tickets'),
    recentActivities: () => import('@/components/dashboard/RecentActivities'),
    personalWorkflow: () => import('@/components/dashboard/PersonalWorkflow'),
    recentDocuments: () => import('@/components/dashboard/RecentDocuments'),
    statistics: () => import('@/components/dashboard/Statistics'),
    userDefined: () => import('@/components/dashboard/UserDefined'),
    quickLinks: () => import('@/components/dashboard/QuickLinks'),
    normalHours: () => import('@/components/dashboard/HoursInfo'),
    ticketsInfo: () => import('@/components/dashboard/TicketsInfo')
  }
}
</script>
